<!-- 预约页 -->
<template>
  <div class="learner-reserve">
    <!-- <div class="learner-reserve-head">
      <img
        @click="handleBack"
        src="@/assets/images/back.png"
        style="width: 20px; height:20px"
      />
    </div> -->
    <div class="learner-reserve-container">
      <div v-if="reserve.length" class="learner-reserve-course-list">
        <div
          class="learner-reserve-course"
          v-for="group in reserve"
          :key="group.id"
        >
          <div class="cover">
            <img
              src="@/assets/images/sibaiji.jpg"
              style="width: 110px;height: 80px"
            />
          </div>
          <div class="content">
            <div class="title">
              {{ group.groupName }}
            </div>
            <div class="teacher">
              <img v-if="group.teacherDTOList && group.teacherDTOList.length && group.teacherDTOList[0].avatar" :src="group.teacherDTOList[0].avatar" />
              <img v-else src="@/assets/images/avator.jpg" />
              {{ group.teacherDTOList && group.teacherDTOList.length >= 2 ? 
                `${group.teacherDTOList[0].teacherName}...等${group.teacherDTOList.length}位导师` :
                group.teacherDTOList && group.teacherDTOList.length === 1 ?
                group.teacherDTOList[0].teacherName :
                "暂未分配导师" }}
            </div>
            <div class="progress">
              已完成 {{ group.classedNum }}/{{ group.classTotal }}
            </div>
          </div>
          <div
            v-if="group.reserveStatus !== 1"
            class="btn"
            @click="handleReserve(group)"
          >
            去预约
          </div>
          <div v-else-if="group.reserveStatus === 1" class="btn" @click="handleReserving">
            预约中
          </div>
          <!-- hide temporary for PM requirement -->
          <!-- <div v-else-if="group.reserveStatus === 2" class="btn" @click="handleReserve(group)">
            通过
          </div> -->
        </div>
      </div>
      <div v-else class="learner-reserve-empty">
        <img src="@/assets/images/emptyCourse.png" style="width: 125px" />
        <div class="learner-reserve-empty-text">暂时没有需要预约的课程噢～</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getReserveClass } from "@/api/learner";

export default {
  data() {
    return {
      reserve: [],
    };
  },
  components: {},
  async mounted() {
    let reserve = await this.getReserveClass();
    this.reserve = reserve;
  },
  created() {
    document.title = "预约上课";
  },
  // 方法集合
  methods: {
    getReserveClass,
    handleBack() {
      this.$router.back();
    },
    handleReserve(group) {
      const { id, profession, source, courseType, groupName, teacherDTOList, duration } = group;
      const ids = (teacherDTOList && teacherDTOList.map(item => {return item.id}).join(",")) || [];
      this.$router.push(
        `/reserveDetial?id=${id}&profession=${profession}&source=${source}&courseType=${courseType}&lessonName=${groupName}&teacherId=${ids || ""}&duration=${duration}`
      );
    },
    handleReserving() {
        this.$toast('该班级正在预约中~')
    }
  },
};
</script>

<style lang="scss" scoped>
.learner-reserve {
  height: 100vh;
  background: #fff;
  &-head {
    padding: 0px 5px 5px 20px;
  }
  &-course {
    display: flex;
    position: relative;
    margin: 20px 15px;
    border-bottom: 1px solid #f3f3f3;
    .content {
      padding-left: 12px;
      .title {
        width: 200px;
        font-size: 16px;
        color: #444f6a;
        padding: 2px 0 13px 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .teacher {
        font-size: 14px;
        color: #797c80;
        padding: 2px 0 13px 0;
        display: flex;
        align-items: center;
        img {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          margin-right: 5px;
        }
      }
      .progress {
        font-size: 14px;
        color: #797c80;
        padding: 0px 0 13px 0;
      }
    }
    .btn {
      width: 66px;
      height: 28px;
      border-radius: 4px;
      border: 1px solid #f65963;
      font-size: 12px;
      color: #f65963;
      text-align: center;
      line-height: 30px;
      position: absolute;
      right: 20px;
      bottom: 10px;
    }
  }
  &-empty {
    font-size: 16px;
    color: #c6c6c6;
    width: 220px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-text {
      padding-top: 30px;
    }
  }
}
</style>
